import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-metaverse',
  templateUrl: './metaverse.component.html',
  styleUrls: ['./metaverse.component.css']
})
export class MetaverseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
